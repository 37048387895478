.country-field {
    .flag-dropdown {
        height: 44px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: transparent !important;
        @include border(1px, #dbe4ed!important);
        border-right: 1px solid $gray300 !important;
        .selected-flag {
            width: 50px;
            padding: 0 12px;
            &:focus {
                @include border(1px, $primary600);
                border-right: 1px solid $primary600 !important;
            }
        }
    }
    .react-tel-input {
        height: 44px;
        width: 50px;
    }
    .react-tel-input .form-control {
        display: none;
    }
}
.location-search {
    .search-wrapper {
        max-width: 100%;
        width: 100%;
        .css-1s2u09g-control {
            padding-left: 50px;
            background-image: url("../images/search-icon.svg");
            background-repeat: no-repeat;
            background-position: 18px center !important;
            &:focus {
                background-color: #ffffff;
            }
        }
        .css-1pahdxg-control {
            padding-left: 50px;
            background-image: url("../images/search-icon.svg");
            background-repeat: no-repeat;
            background-position: 18px center !important;
            &:focus {
                background-color: #ffffff;
            }
        }
    }
}

.phone-number-field {
    display: flex;
    .input-wrapper {
        width: calc(100% - 50px);
        margin-bottom: 0 !important;
        input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.values_popup{
    min-width: 910px;
    max-width: 910px;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 12px;
    .modal-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        border-bottom: 1px solid #E4E6E8;
        padding-bottom: 20px;
        h6{
            font-size: 22px;
            font-weight: 700;
            color: #0A1629;
            line-height: 1.5;
            font-family: $font-NunitoSans;
        }
    }
    .valueContent{
        display: flex;
        flex-wrap: wrap;   
         overflow: auto;
        max-height: 40vh;
        li{
            width: 50%;
            // border: 1px solid red;
            border-bottom: 1px solid #E4E6E8;
            padding: 24px 0;
            &:nth-child(odd){
                padding-right: 12px;
            }
            &:nth-child(even){
                padding-left: 12px;
            }
            .heading{
                font-size: 14px;
                font-weight: 700;
                color: #7D8592;
                line-height: 1.5;
                font-family: $font-NunitoSans;
                margin-bottom: 10px;
            }
            .content{
                font-size: 14px;
                font-weight: 400;
                color: #7D8592;
                line-height: 1.6;
                font-family: $font-NunitoSans;
            }
        }
    }
}

.goal_popup{
    min-width: 910px;
    max-width: 910px;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 12px;
.modal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-bottom: 1px solid #E4E6E8;
    padding-bottom: 20px;
    h6{
        font-size: 22px;
        font-weight: 700;
        color: #0A1629;
        line-height: 1.5;
        font-family: $font-NunitoSans;
    }
}
.valueContent{
    display: flex;
    flex-wrap: wrap;   
     
    li{
        width: 50%;
        padding: 24px 0;
        &:nth-child(odd){
            padding-right: 12px;
        }
        &:nth-child(even){
            padding-left: 12px;
        }
        .heading{
            font-size: 14px;
            font-weight: 700;
            color: #7D8592;
            line-height: 1.5;
            font-family: $font-NunitoSans;
            margin-bottom: 10px;
        }
        .content{
            font-size: 14px;
            font-weight: 400;
            color: #7D8592;
            line-height: 1.6;
            font-family: $font-NunitoSans;
        }
    }
}
.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
}
.goalWrapper{
    overflow: auto;
    max-height: 70vh;
    .section{
       
        // border: 1px solid red;
        border-bottom: 1px solid #E4E6E8;
        margin-top: 24px;
        h3{
            font-size: 16px;
            font-weight: 700;
            color: #0A1629;
            line-height: 1.5;
            font-family: $font-NunitoSans;
            margin-bottom: 12px;
        }
        h4{
            font-size: 14px;
            font-weight: 700;
            color: #7D8592;
            line-height: 1.5;
            font-family: $font-NunitoSans;
            margin-bottom: 18px;
        }
        p{
            font-size: 14px;
            font-weight: 400;
            color: #7D8592;
            line-height: 1.5;
            font-family: $font-NunitoSans;
        }
    }
}
  
}
