.dashboard-box {
    &.bg-transparent {
        .filter-box {
            margin-top: 5px;
            margin-bottom: 25px;
            .tab-sec {
                ul {
                    padding: 4px;
                    background: $light-gray;
                    @include radius(24px);
                    li {
                        width: 100%;
                        padding: 13px 25px;
                        text-align: center;
                        cursor: pointer;
                        font-weight: $weight-400;
                        font-size: 16px;
                        color: $seconday-black;
                        @include radius(20px);
                        &.active {
                            background: $primary600;
                            color: $white;
                            @include radius(20px);
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}
.library-card {
    background: $white;
    @include border(1px, $gray-border);
    @include radius(16px);
    padding: 16px;
    margin-bottom: 24px;
    .card-image {
        figure {
            height: 145px;
            @include radius(8px);
            overflow: hidden;
            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include radius(8px);
            }
        }
    }
    .card-body {
        padding-top: 12px;
        h3 {
            margin-bottom: 8px;
            font-weight: $weight-600;
            font-size: 16px;
            color: $black;
        }
        h4 {
            margin-bottom: 8px;
            font-weight: $weight-500;
            font-size: 14px;
            color: $secondary;
            span {
                margin: 0 2px;
            }
        }
        h5 {
            font-size: 10px;
            color: $light-text;
            font-weight: $weight-600;
        }
        .user-info {
            @include flexprop;
            @include flexalign(center);
            margin-bottom: 8px;
            .user-image {
                figure {
                    width: 20px;
                    height: 20px;
                    @include radius(50%);
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        @include radius(50%);
                    }
                }
            }
            .user-name {
                width: calc(100% - 12px);
                padding-left: 8px;
                p {
                    font-weight: $weight-600;
                    font-size: 10px;
                    color: $seconday-black;
                }
            }
        }
    }
}
