@import "auth";
@import "core";
@import "user";
@import "userdetail";
@import "library";
@import "notificationadd";
@import "notificationedit";
@import "subadmin";
@import "accounts";
@import "dashboard";
@import "airtable-sync";
@import "report";
@import "review";
.counter-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .input-wrapper {
    margin-bottom: 0;
  }
  .remove-ic {
    margin-right: 10px;
    cursor: pointer;
  }
}
.chip-item {
  .MuiButtonBase-root {
    &.Mui-disabled {
      opacity: 0.5;
      background: #8080809e !important;
    }
  }
}
.input-wrapper {
  &.multiple-checkbox {
    .MuiAutocomplete-root {
      .MuiInputBase-root {
        height: auto;
        min-height: 44px;
        &.MuiOutlinedInput-root {
          padding: 17px 60px 0px 10px !important;
        }
      }
      .MuiAutocomplete-endAdornment {
        top: 14px !important;
      }
    }
    .MuiAutocomplete-input {
      padding: 5px 4px 7.5px 5px !important;
    }
  }
}
.table-wrapper {
  thead {
    tr {
      td {
        white-space: nowrap;
      }
    }
  }
}
.chip-item {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.MuiChip-root {
  &.user-detail-chip {
    margin: 0 0 10px 10px;
    border-radius: 8px;
    .MuiChip-label {
      font-weight: 700;
    }
  }
}
.user-description {
  &.bgblue {
    &.pos-top {
      margin-top: 0;
      position: relative;
      top: -5px;
    }
  }
  &.bgred {
    &.pos-top {
      margin-top: 0;
      position: relative;
      top: -5px;
    }
  }
}
.button-sec {
  &.flex-wrap {
    display: flex;
    align-items: center;
  }
  label {
    margin-bottom: 0;
    white-space: nowrap;
  }
}
.text-delete-wrap {
  display: flex;
  align-items: center;
  .delete-icon {
    position: relative;
    cursor: pointer;
    margin-left: 15px;
    top: -8px;
  }
}

.user-profile-sec{
    .user-info-sec{
        .user-info-right{
            .button-sec{
                width: max-content;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
        }
    }
}
.choose-files-wrap{
  height:85px;
  display: flex;
  align-items: flex-start;
  .choose-file{
    width:calc(100% - 90px);
    height:48px;
    position: relative;
    overflow: hidden;
    background: linear-gradient(0deg, #E1E9FE, #E1E9FE),
    linear-gradient(0deg, #C6D2D8, #C6D2D8);
    border:dashed 1px #C6D2D8;
    border-radius:16px;
    display: flex;
    align-items:center;
    justify-content: center;
    cursor: pointer;
    span{
      font-size:16px;
      font-weight:500;
      color:#273239;
    }
    .upload-ic{
      width:25px;
      height:21px;
      margin-right:10px;
      background:url("../images/ic-upload.svg") no-repeat
    }
    input{
      width:100%;
      height:100%;
      position: absolute;
      left:0;
      top:0;
      opacity:0;
    }
  }
}
.uploaded-wrap{
  margin-top:20px;
  display: flex;
  align-items: flex-start;
  .item{
    width:200px;
    height:200px;
    margin:0 10px 10px;
    &:first-child{
      margin-left:0;
    }
    figure{
      width:100%;
      height:100%;
      overflow: hidden;
      padding:5px;
      border: 1px solid #ddd;
      border-radius:5px;
      img{
        width:100%;
        height:100%;
        border-radius:5px;
        object-fit:cover;
        object-position:center center;
      }
    }
  }
}
.MuiFormControl-root{
  &.select-wrap{
    width:100%;
    border:1px solid #dbe4ed;
    border-radius:4px;
  }
}
.react-calendar__tile{
  &.selected{
    border: 1px solid #006edc;
    border-radius: 0;
    background-color: #c2e1ff;
    color: #006edc;
    &:focus{
      background-color: #c2e1ff !important;
    }
  }
}