.breadcrumb {
    li {
        display: inline-block;
        color: $black;
        font-size: 18px;
        a {
            font-weight: $weight-700;
            color: $black;
            @include flexprop;
            @include flexalign(center);
            img {
                filter: invert(18%) sepia(14%) saturate(719%) hue-rotate(160deg) brightness(94%) contrast(96%);
                transform: rotate(180deg);
                padding: 0 6px 0 0;
                height: 15px;
            }
        }
        margin-right: 6px;
    }
}
