.filter-box {
    margin-bottom: 30px;
    .filter-header {
        margin-bottom: 22px;
        a {
            text-decoration: none;
        }
        h5 {
            font-weight: $weight-700;
            color: $black;
            font-size: 22px;
            &.wt-500 {
                font-weight: $weight-500;
            }
        }
        .btn + .btn {
            margin: 0 0 0 10px;
        }
        .breadcrumb-left {
            @include flexprop;
        }
    }
    .filter-content {
        @include border(1px, $bordercolor);
        @include radius(10px);
        background: $light-bg;
        padding: 20px;
        h3 {
            font-weight: $weight-700;
            color: $black;
            font-size: 20px;
        }
        .filter-right {
            .search-field-sec {
                margin-right: 15px;
                .search-wrapper {
                    width: 420px;
                    input[type="search"] {
                        padding-left: 50px;
                        background-image: url("../images/search-icon.svg");
                        background-repeat: no-repeat;
                        background-position: 18px center !important;
                        &:focus {
                            background-color: #ffffff;
                        }
                    }
                }
            }
            .btn {
                &.primary-btn {
                    img {
                        filter: brightness(0) invert(1);
                        height: 18px;
                        vertical-align: -4px;
                    }
                }
            }
        }
    }
}

.setting-options {
    position: relative;
    cursor: pointer;
    .setting-btn {
        background: $white;
        padding: 14px;
        @include box-shadow(0px 6px 58px rgba(196, 203, 214, 0.103611));
        @include radius(12px);
        width: 48px;
        height: 48px;
        @include flexprop;
        @include flexalign(center);
        @include flexjustify(center);
        &.bggreen {
            width: auto;
            height: auto;
        }
    }
    &.plan-dropdown {
        ul {
            left: 0;
            li {
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    &.active,
                    &:hover {
                        background-color: transparent;
                        color: $seconday-black;
                        cursor: default;
                        img {
                            filter: brightness(0) invert(1);
                        }
                    }
                }
                li {
                    &.active,
                    &:hover {
                        background-color: $primary600;
                        color: $white;
                        cursor: pointer !important;
                        img {
                            filter: brightness(0) invert(1);
                        }
                    }
                    &:nth-of-type(2),
                    &:nth-of-type(3) {
                        &.active,
                        &:hover {
                            background-color: $primary600;
                            color: $white;
                            cursor: pointer !important;
                            img {
                                filter: brightness(0) invert(1);
                            }
                        }
                    }
                }
            }
        }
    }
    ul {
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: 10px;
        min-width: 182px;
        font-weight: $weight-500;
        font-size: 16px;
        line-height: 24px;
        color: $seconday-black;
        text-align: left;
        padding: 6px 0;
        background: $white;
        @include box-shadow(0px 2px 4px rgba($black, 0.6));
        @include radius(12px);
        transition: 0.5s;
        z-index: 2;
        overflow: hidden;
        &:not(.active) {
            opacity: 0;
            visibility: hidden;
        }
        li {
            padding: 6px 18px;
            transition: 0.5s;
            position: relative;
            &.active,
            &:hover {
                background-color: $primary600;
                color: $white;
                img {
                    filter: brightness(0) invert(1);
                }
            }
            img {
                transition: 0.5s;
                margin-right: 10px;
                width: 16px;
                vertical-align: -4px;
            }
        }
    }
}
