$columns: 12;
@for $i from 1 through $columns {
    .col-#{$i} {
        @include fwidth($width: 100% / $columns * $i);
    }
}
@media (min-width: 576px) {
    @for $i from 1 through $columns {
        .col-sm-#{$i} {
            @include fwidth($width: 100% / $columns * $i);
        }
    }
}
@media (min-width: 768px) {
    @for $i from 1 through $columns {
        .col-md-#{$i} {
            @include fwidth($width: 100% / $columns * $i);
        }
    }
}
@media (min-width: 992px) {
    @for $i from 1 through $columns {
        .col-lg-#{$i} {
            @include fwidth($width: 100% / $columns * $i);
        }
    }
}
@media (min-width: 1200px) {
    @for $i from 1 through $columns {
        .col-xl-#{$i} {
            @include fwidth($width: 100% / $columns * $i);
        }
    }
}
.container {
    width: 100%;
    max-width: 1245px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.container-left {
    padding-left: calc(50vw - 615.8px);
    @media screen and (max-width: 1275px) {
        padding-left: 15px;
    }
}
