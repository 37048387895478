.pagination-wrapper {
    @include flexjustify(flex-end);
    .pagination-list {
        margin-right: 20px;
        .pagination {
            margin: 0 0 0 20px;
            li {
                display: inline-block;
                vertical-align: middle;
                & + li {
                    margin-left: 20px;
                }
            }
            .page-item {
                button {
                    text-decoration: none;
                    color: $secondary;
                    &:hover {
                        color: $primary600;
                        cursor: pointer;
                    }
                    &.pagination-link {
                        width: 28px;
                        height: 28px;
                        background-color: $gray100;
                        display: inline-block;
                        font-size: 24px;
                        line-height: 22px;
                        text-align: center;
                        &:hover {
                            color: $primary600;
                            cursor: pointer;
                        }
                    }
                    &.page-link {
                        font-size: 16px;
                        line-height: 22px;
                        &.active {
                            color: $primary600;
                        }
                        &:hover {
                            color: $primary600;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .arr-btn,
    .page-item:first-child a,
    .page-item:last-child a {
        width: 28px;
        height: 28px;
        background-color: $gray100;
        display: inline-block;
        font-size: 24px;
        line-height: 22px;
        text-align: center;
        img {
            width: 10px;
        }
    }
    .arr-btn {
        @include flexprop;
        @include flexjustify(center);
        @include flexalign(center);
    }
}
.per-page {
    select {
        height: 32px;
        width: 75px;
        padding: 0 30px 0 8px;
        color: $gray500;
    }
    .values {
        font-weight: $weight-500;
        margin-left: 18px;
        color: $secondary;
        .of {
            margin: 0 10px;
        }
    }
    .number-box {
        select {
            @include radius(5px);
        }
    }
}
.number-box {
    .react-select > div > div:first-of-type {
        background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%23667085' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: calc(100% - 12px) center !important;
        -moz-appearance: none !important;
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        padding-right: 30px;
    }
}
