.accounts-wrap {
    .profile-image {
        margin-bottom: 22px;
        figure {
            width: 100px;
            height: 100px;
            @include radius(50%);
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include radius(50%);
            }
        }
    }
    .update-password-link {
        margin-bottom: 20px;
        span {
            cursor: pointer;
            display: inline-block;
            font-weight: $weight-600;
            font-size: 16px;
            line-height: 20px;
            color: $linkcolor;
            text-decoration: underline;
        }
    }
    .profile-upload-file {
        position: relative;
        figure {
            width: 100px;
            height: 100px;
            @include radius(50%);
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include radius(50%);
            }
        }
        .upload-icon {
            position: absolute;
            top: 84%;
            left: 84%;
            transform: translate(-50%, -50%);
            width: 35px;
            height: 35px;
            @include radius(50%);
            input[type="file"] {
                display: none;
                position: absolute;
                top: 84%;
                left: 84%;
                transform: translate(-50%, -50%);
                width: 35px;
                height: 35px;
                @include radius(50%);
            }
            label {
                margin-bottom: 0;
            }
            figure,
            label {
                width: 100%;
                height: 100%;
                cursor: pointer;
                @include radius(50%);
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @include radius(50%);
                }
            }
        }
        input[type="file"] {
            display: none;
            position: absolute;
            top: 84%;
            left: 84%;
            transform: translate(-50%, -50%);
            width: 35px;
            height: 35px;
            @include radius(50%);
        }
    }
}
.image-upload > input {
    display: none;
}
