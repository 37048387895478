.table-wrapper {
    margin-bottom: 30px;
    overflow-x: auto;
    max-height: 47vh;
    @include box-shadow(2px 2px 5px rgba($primary600, 0.3));
    @include radius(10px);
    &.mb-0 {
        margin-bottom: 0;
    }
    &.mb-18 {
        margin-bottom: 18px;
    }
    table {
        width: 100%;
        font-size: 14.5px;
        min-width: 940px;
        thead tr {
            background-color: $primary600;
            color: $white;
            position: sticky;
            top: 0;
            // z-index: 1;
            td,
            th {
                font-weight: $weight-500;
                .name {
                    cursor: pointer;
                }
                .sort-arrow {
                    margin-left: 8px;
                    display: inline-block;
                    vertical-align: middle;
                    img {
                        cursor: pointer;
                    }
                    button {
                        padding: 0;
                        &:first-child {
                            margin-right: 1px;
                        }
                    }
                }
            }
            img {
                height: 13px;
            }
        }
        tbody {
            tr td {
                padding: 16px 10px;
                color: $secondary;
            }
            tr td.text-spacing {
                padding: 18px 10px;
                color: $secondary;
            }
            img {
                width: 25px;
                height: 25px;
                @include radius(50%);
            }
        }
        tr td,
        tr th {
            padding: 18px 10px;
            vertical-align: middle;
            text-align: center;
            a {
                color: $primary600;
                text-decoration: underline;
            }
        }

        tr td:first-child {
            padding-left: 20px;
        }
        tr td:last-child {
            padding-right: 20px;
        }
        tbody tr:nth-child(even) {
            background-color: $gray100;
        }
        .status {
            padding: 5px 10px;
            min-width: 100px;
            @include border(1px, $gray500);
            background-color: $white;
            display: inline-block;
            border-radius: 4px;
            &.danger {
                background-color: $danger;
                color: $white;
                border-color: $danger;
            }
            &.gray {
                background-color: $secondary;
                color: $white;
                border-color: $secondary;
            }
            &.sucess {
                background-color: $success500;
                color: $white;
                border-color: $success500;
            }
            &.pending {
                width: auto;
                height: 28px;
                line-height: 26px;
                padding: 0 8px;
                background-position: calc(100% - 6px) center !important;
                background-size: 14px;
                font-size: 14.5px;
            }
            &.progress {
                border: 0;
                background: $yellow-color;
                color: $white;
                border-color: $yellow-color;
            }
            &.completed {
                background-color: $success500;
                color: $white;
                border-color: $success500;
            }
        }
        .action {
            @include border(1px, $bordercolor);
            padding: 5px;
            @include radius(4px);
            @include transition;
            margin: 5px 0;
            background-color: $white;
            & + .action {
                margin-left: 10px;
            }
            &.red-border {
                border-color: $primary500;
            }

            &:disabled {
                opacity: 0.5;
            }
        }
        .hand-icon {
            cursor: pointer;
        }
    }
}

.selectbuttonstyliing:before {
    border-bottom: 1px solid rgba(110, 52, 52, 0.42) !important;
    font-size: 30px;
}

.subadmin-management {
    .table-wrapper {
        max-height: 41.6vh;
    }
}
.report-management {
    .table-wrapper {
        max-height: 48vh;
    }
}
.invoice-management {
    .table-wrapper {
        max-height: 49.8vh;
    }
}

.selectbuttonstyliing{
    border: none;
    width: 200px;
    background-color: transparent !important;
    }
    
.tableAction{
    display: flex;
    align-items: center;
    gap: 5px;
    width: -webkit-fill-available;
    justify-content: center;
    button{
        min-width: 40px;
    }
}