.airtable-sync-wrapper {
    .btn {
        &.small {
            width: 148px;
        }
    }
    .radio-box {
        background-color: $white;
        @include radius(5px);
        border: 1px solid $gray800;
        box-shadow: 0px 4px 4px $gray800;
        transition: 0.3s linear all;
        margin-bottom: 24px;
        padding: 16px;
        @include flexprop;
        @include flexalign(center);
        @include flexjustify(space-between);
        .radio-right-content {
            p {
                font-size: 14px;
                color: $light-text;
                line-height: 20px;
                font-weight: $weight-500;
            }
        }
    }
    .airtable-content-section {
        padding: 6px 0 22px;
        .image-sec {
            figure {
                width: 51px;
                height: 51px;
                margin: auto;
                position: relative;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &::before {
                    content: "";
                    display: block;
                    width: calc(50vw - 243px);
                    height: 2px;
                    margin: 0 0 0 85px;
                    background: $border300;
                    left: 0;
                    top: 50%;
                    position: absolute;
                }
                &::after {
                    content: "";
                    display: block;
                    width: calc(50vw - 243px);
                    height: 2px;
                    margin: 0 85px 0 0;
                    background: $border300;
                    right: 0;
                    top: 50%;
                    position: absolute;
                }
            }
        }
    }
    .airtable-info {
        h2 {
            margin-bottom: 22px;
            font-weight: $weight-700;
            font-size: 22px;
            color: $seconday-black;
        }
        p {
            margin-bottom: 15px;
            font-size: 16px;
            color: $gray700;
            line-height: 20px;
            a {
                color: $linkcolor;
                text-decoration: none;
            }
        }
    }
}
