.side-bar {
    position: fixed;
    z-index: 9;
    height: calc(100% - 76px);
    overflow: auto;
    top: 76px;
    left: 0;
    width: 270px;
    background-color: $white;
    .side-menu {
        li a {
            padding: 15px 20px;
            @include flexprop;
            @include flexalign(center);
            line-height: 1.4;
            @include width;
            @include transition;
            color: $secondary;
            text-decoration: none;
            &.active {
                border-bottom: 1px solid $white;
                border-top: 1px solid $white;
            }
            &:hover,
            &.active {
                background-color: $primary600;
                color: $white;
                img {
                    filter: brightness(0) invert(1);
                }
            }
            img {
                @include transition;
            }
        }
        img {
            margin-right: 10px;
            width: 21px;
            height: 21px;
            vertical-align: -4px;
        }
    }
}
.dashboard-wrapper {
    min-height: 100vh;
    padding: 95px 20px 15px 290px;
    .dashboard-box {
        background-color: $white;
        padding: 25px 25px;
        min-height: calc(100vh - 115px);
        @include box-shadow(0px 4px 20px rgba(0, 0, 0, 0.04));
        @include radius(10px);
        &.bg-transparent {
            background: transparent;
            box-shadow: none;
            padding: 0;
        }
    }
}
.dash-card {
    .dash-header {
        h5 {
            font-weight: $weight-700;
            color: $black;
        }
        margin-bottom: 30px;
    }
    .dash-body {
        & + .dash-body {
            border-top: 1px solid $gray300;
            margin-top: 40px;
            padding-top: 40px;
        }
        h3 {
            font-weight: $weight-700;
            margin-bottom: 32px;
            color: $black;
            font-size: 24px;
        }
    }
}
.calenderStyling{
    width: 100px !important;
}