.content-box {
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
    p {
        font-weight: $weight-500;
        font-size: 14px;
        color: $light-text;
        line-height: 24px;
    }
    &.para {
        flex-direction: column;
        label,
        p {
            width: 100%;
        }
        label {
            margin-bottom: 8px;
        }
    }
    label {
        color: $black-dark;
        margin-bottom: 0;
    }
}
