@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
$font-Montserrat: "Montserrat", sans-serif;
$font-NunitoSans: "Nunito Sans", sans-serif;
$weight-300: 300;
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 700;
$weight-900: 700;
