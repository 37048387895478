button,
.btn,
.btn-reset {
    border: 0;
    cursor: pointer;
    font-family: $font-Montserrat;
    background-color: transparent;
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        cursor: -moz-not-allowed;
        cursor: -webkit-not-allowed;
        cursor: -ms-not-allowed;
        cursor: -o-not-allowed;
    }
    &[disabled] {
        opacity: 0.7;
        cursor: not-allowed;
        cursor: -moz-not-allowed;
        cursor: -webkit-not-allowed;
        cursor: -ms-not-allowed;
        cursor: -o-not-allowed;
    }
}
.button-wrapper {
    margin-top: 35px;
    .btn {
        min-width: 150px;
    }
    .btn + .btn {
        margin: 0 0 0 40px;
    }
}
.btn {
    height: 60px;
    line-height: 42px;
    font-size: 16px;
    font-weight: $weight-500;
    padding: 0 16px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    @include transition;
    @include radius(16px);
    &.small {
        height: 44px;
        @include radius(5px);
    }
    &.w-100 {
        @include width;
    }
    & + .btn {
        margin-left: 15px;
    }
    img {
        vertical-align: middle;
        margin-right: 10px;
    }
    &.big {
        height: 60px;
        line-height: 58px;
        min-width: 115px;
    }
    &:after {
        background: $white;
        content: "";
        height: 155px;
        left: -75px;
        opacity: 0.2;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
    }
    &:hover:after {
        left: 120%;
        transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
    }
}
.primary-btn {
    background-color: $primary600;
    color: $white;
}
.secondary-btn {
    background-color: $danger;
    color: $white;
    img {
        filter: brightness(0) invert(1);
    }
}
.ternary-btn {
    background-color: $secondary;
    color: $white;
    img {
        filter: brightness(0) invert(1);
    }
}
.outline-btn {
    @include border(1px, $primary600);
    color: $primary600;
    &:hover {
        color: $white;
        background-color: $primary600;
        img {
            filter: brightness(0) invert(1);
        }
    }
    img {
        @include transition;
    }
}
.outline-warn-btn {
    @include border(1px, $danger);
    color: $danger;
    &:hover {
        color: $white;
        background-color: $danger;
        img {
            filter: brightness(0) invert(1);
        }
    }
    img {
        @include transition;
    }
}
.success-btn {
    color: $white;
    background-color: $success500;
}
.error-btn {
    background-color: $primary700;
    color: $white;
    border-color: $primary700;
}
.btnStyling{
    display: flex;
    width: 260px !important;
    padding: 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 12px !important;
    align-self: stretch !important;
    border-radius: 90px !important;
background: var(--v-1-black, #000D14) !important;
color: white !important;
margin-top: 20px !important;
}
