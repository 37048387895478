.notification-detail-wrap {
    .filter-header {
        margin-bottom: 22px;
    }
    .notification-text-area {
        position: relative;
        .text-limit {
            @include flexprop;
            @include flexjustify(space-between);
            p {
                margin-top: 7px;
                text-align: right;
                color: $gray500;
                width: 100%;
                font-size: 80%;
            }
        }
    }
    .btn-wrap {
        padding-top: 100px;
        @include flexprop;
        @include flexjustify(center);
        button {
            width: 405px;
            max-width: 100%;
        }
        .prev-btn {
            button {
                margin-left: 22px;
            }
        }
    }
}
