body {
  font-family: $font-Montserrat;
}
a {
  text-decoration: none;
}
.m-auto {
  margin: 0 auto;
}
.mt-10 {
  margin-top: 15px;
}
.f-bold {
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}
.corporate-field {
  label {
    font-size: 12px;
    font-weight: 500;
    color: #7d8592;
  }
  .input-wrapper {
    margin-bottom: 0 !important;
  }
}
.corporate-check {
  label {
    span {
      font-size: 15px;
      font-weight: 500;
      color: #7d8592;
    }
  }
}
.input-post-icon {
  position: absolute;
  max-width: 25px;
  cursor: pointer;
  height: 16px;
  top: 32px;
  right: 15px;
}

.currencyInputWrap {
  position: relative;
  .input-wrapper {
    input {
      padding-left: 60px;
      font-weight: bold;
    }
  }
  .currecycode {
    position: absolute;
    left: 5px;
    top: 21px;
    z-index: 1;
    color: #6992f8;
    font-weight: 700;
    font-size: 16px;
  }
}

.mainhead-listcont {
  .MuiPaper-root {
    .MuiButtonBase-root {
      &.Mui-disabled {
        opacity: 1 !important;
      }
    }
  }
}

.mainhead-paracont {
  .MuiPaper-root {
    .MuiButtonBase-root {
      &.Mui-disabled {
        opacity: 1 !important;
      }
    }
  }
}

.timezoneinput {
  width: 240px;
  height: 30px;
  border: 1px solid rgb(204, 204, 204);
  text-align: center;
  line-height: 50px;
  border-radius: 10px;
  margin: 0px 10px;
}
