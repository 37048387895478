.review-table-wrapper table tbody tr td:nth-of-type(2) {
    line-height: 1.4;
    max-width: 140px;
}
.message-field textarea {
    min-height: 360px;
}
.review-table-wrapper table tbody tr td:nth-of-type(3) {
    vertical-align: middle;
}
.review-table-wrapper table tbody tr td:nth-of-type(3) img {
    width: auto;
    height: auto;
    margin-right: 3px;
    display: inline-block;
    vertical-align: middle;
}
.review-table-wrapper table tbody tr td:nth-of-type(3) img:last-child {
    margin-right: 7px;
}
