.dashboard-card-wrapper {
    .card-body {
        background-color: $white;
        text-align: center;
        color: $white;
        @include radius(10px);
        @include border(1px, $gray800);
        @include box-shadow(0px 4px 4px $gray800);
        transition: 0.3s linear all;
        margin-bottom: 24px;
        &:hover {
            @include box-shadow(0px 8px 8px $gray800);
        }
        .card-content {
            height: 100px;
            padding: 15px;
            @include flexprop;
            @include flexalign(center);
            @include flexjustify(center);
            flex-direction: column;
        }
        .card-title {
            font-weight: $weight-700;
            font-size: 40px;
            color: $secondary;
        }
        .more-info-container {
            padding: 12px 15px;
            @include border(1px, rgba(208, 213, 221, 0.25));
            background: rgba(208, 213, 221, 0.25);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            p {
                color: $secondary;
                font-size: 16px;
                font-weight: $weight-600;
                text-transform: capitalize;
            }
        }
    }
}
.dashboard-tab {
    padding-top: 15px;
    .tabbing-box {
        margin: 0 -25px 40px;
        li {
            box-shadow: 0 1px 0 $gray300;
            background-color: $white;
            width: 100%;
            padding: 15px 30px;
            text-align: center;
            cursor: pointer;
            color: $secondary;
            &.active {
                box-shadow: 0 4px 0 $primary600;
                font-weight: $weight-700;
                color: $primary600;
            }
        }
    }
}
.dashboard-filter {
    @include flexprop;
    @include flexjustify(space-between);
    .input-wrapper {
        width: 420px;
    }
    .search-field-sec {
        .search-wrapper {
            width: 420px;
            input[type="search"] {
                padding-left: 50px;
                background-image: url("../images/search-icon.svg");
                background-repeat: no-repeat;
                background-position: 18px center !important;
                &:focus {
                    background-color: #ffffff;
                }
            }
        }
    }
}
