.row {
    @include row;
    @include flexprop;
    @include flexwrap;
}
/*---------- flex css -------------*/
.d-flex {
    @include flexprop;
}
.align-item-center {
    @include flexalign(center);
}
.align-item-end {
    @include flexalign(end);
}
.justify-content-center {
    @include flexjustify(center);
}
.justify-content-between {
    @include flexjustify(space-between);
}
.flex-center {
    @extend .align-item-center;
    @extend .justify-content-center;
}
.flex-center-between {
    @extend .align-item-center;
    @extend .justify-content-between;
}
/*---------- text css -------------*/
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-uppercase {
    text-transform: uppercase;
}
/*---------- img css -------------*/
img {
    max-width: 100%;
}
.object-fit {
    @include width;
    @include height;
    object-fit: cover;
}
.over-hidden {
    overflow: hidden;
}
.list-icon {
    li {
        padding-left: 30px;
        @include bgImg($name: "check.svg", $size: 16px, $position: left center);
        line-height: 1.5;
        & + li {
            margin-top: 18px;
        }
    }
}
.hand-icon {
    cursor: pointer;
}
.inputstyling{
    background-color: #E1E9FE !important;
}
.headerpopup{
    color: var(--Black, #000D14) !important;
font-family: Montserrat !important;
font-size: 32px !important;
font-style: normal;
font-weight: 700 !important;
line-height: 150% !important; 
}
.uploadinglistSubtext{
    color: #273239;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 160%; /* 25.6px */
}
.dialogboxStyling .MuiDialog-container .MuiPaper-elevation{
    padding: 30px !important;
    width: 100% !important;
    border-radius: 24px;
}

.custom-file-input-container {
    position: relative;
    display: inline-block;
  }
  
  .custom-file-input-label {
    background-color: #0074d9;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  #file-input {
    display: none;
  }
  .designinginputbutton {
    width: 100%;
height: 56px;
flex-shrink: 0;
    display: flex;
    justify-content: center; /* Horizontally center the label */
    align-items: center; /* Vertically center the label */
    border-radius: 16px;
border: 1px dashed var(--border, #C6D2D8);
background: var(--blue-light, #E1E9FE);
   
  }
  .centered-label {
    display: flex;
    align-items: center;
  }

