.header {
    padding: 15px 20px;
    position: fixed;
    top: 0;
    left: 0;
    @include width;
    min-height: 76px;
    z-index: 1;
    background-color: $white;
    border-bottom: 1px solid $gray300;
    z-index: 9;
    .logo {
        width: 180px;
        img {
            width: 100%;
        }
    }
    .header-left {
        @include flexprop;
        @include flexalign(center);
        h3 {
            font-size: 16px;
            font-weight: $weight-600;
            color: $gray500;
            margin-right: 2px;
        }
        .notification-image {
            figure {
                @include border(1px, $gray300);
                @include radius(8px);
                width: 45px;
                height: 45px;
                @include flexprop;
                @include flexjustify(center);
                @include flexalign(center);
                position: relative;
                cursor: pointer;
                .border {
                    position: absolute;
                    background-color: $danger;
                    width: 10px;
                    height: 10px;
                    @include radius(50%);
                    right: -5px;
                    top: -5px;
                }
            }
        }
    }
    .header-middle {
        width: calc(100% - 450px);
        .search-field-sec {
            width: 420px;
            .search-wrapper {
                max-width: 100%;
                width: 100%;
                input[type="search"] {
                    padding-left: 50px;
                    background-image: url("../images/search-icon.svg");
                    background-repeat: no-repeat;
                    background-position: 18px center !important;
                    &:focus {
                        background-color: #ffffff;
                    }
                }
            }
        }
    }
}
