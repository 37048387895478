// gray scale colors
$white: #ffffff;
$white200: #f4f9fd;
$gray300: #dbe4ed;
$gray50: #f9fafb;
$gray100: #f2f4f7;
$gray600: #6d8a9c;

$gray200: #e4e7ec;
$gray500: #273239;
$gray700: #344054;
$gray800: #eef6fc;
$light-text: #7d8592;
$black: #273239;
$black-dark: #000000;
$light-black: rgba(0, 0, 0, 0.6);
$light-bg: #f5f5f5;
$light-gray: #e6edf5;

// blue-scale-color
$primary600: #2483c5;
$linkcolor: #2483c5;
$blue300: #8f98ff;

// red scale colors
$primary50: #ffebeb;
$primary100: #ffd7d7;
$primary500: #ff3535;
$primary700: #981824;

// indicator colors
$success: #6ce9a6;
$success500: #12b76a;
$danger: #ff275b;
$green100: #00d097;
$light-green: #e0f9f2;
$aliceBlue: #f0f8ff;

//secondary-colors
$secondary: #3a4b55;
$seconday-black: #0a1629;

//border-colors
$bordercolor: #d0d5dd;
$light-border: #d9d9d9;
$gray-border: #d3e6f3;
$border300: #e4e6e8;
$border400: #c7c7c7;

// paragraph-color
$gray-para: #747474;

// loader-color
$loader-bg: #dcdcdc;
$yellow-color: #f4be5e;
