@import "./colors";
@import "./mix";
@import "./font";

.auth-section {
    .row {
        margin: 0;
    }
    .col-md-6 {
        padding: 0;
    }
    .auth-left {
        background-color: rgba(36, 131, 197, 0.6);
        min-height: 100vh;
        img {
            width: 300px;
            margin-bottom: 30px;
            display: inline-block;
        }
    }
    .auth-right {
        background-color: $white;
        min-height: 100vh;
        @media (max-width: 1024px) {
            height: auto;
        }
        .auth-header {
            margin-bottom: 45px;
            margin-top: 100px;
            @media (max-width: 767px) {
                margin-top: 60px;
            }
            &.mt {
                margin-top: 70px;
            }
            h1 {
                margin-bottom: 6px;
                font-weight: $weight-800;
                font-size: 34px;
                line-height: 1.25;
                text-align: left;
                color: $gray500;
            }
            p {
                font-weight: $weight-300;
                font-size: 18px;
                line-height: 28px;
                color: $secondary;
                text-align: left;
                a {
                    color: $secondary;
                    text-decoration: none;
                }
            }

            h3 {
                font-size: 24px;
                color: $gray500;
            }
            h5 {
                line-height: 1.8;
                color: $gray500;
            }
            .auth-image {
                @include flexprop;
                @include flexjustify(center);
                @include flexalign(center);
                padding-bottom: 60px;
                figure {
                    width: 145px;
                    height: 145px;
                    @include radius(50%);
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        .button-wrapper {
            margin-top: 50px;
        }
        .forgot-text {
            margin-top: 20px;
            a {
                color: $white;
            }
        }
        .mail-sent-link {
            margin-top: 25px;
            p {
                font-weight: $weight-300;
                font-size: 16px;
                line-height: 28px;
                color: $gray600;
                text-align: center;
                span {
                    font-weight: $weight-600;
                    font-size: 18px;
                    line-height: 28px;
                    color: $danger;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
    .left-inner {
        @include flexprop;
        @include flexjustify(space-between);
        flex-direction: column;
        height: 100%;
        padding: 260px 40px 40px;
        width: 100%;
        @media (max-width: 1024px) {
            @include flexjustify(center);
            padding: 40px;
        }
        @media (max-width: 767px) {
            @include flexjustify(center);
        }
        .bottom-sec {
            @media (max-width: 1024px) {
                margin-top: 20px;
            }
            @media (max-width: 767px) {
                margin-top: 20px;
            }
        }
        h3 {
            font-size: 28px;
            font-weight: $weight-600;
            color: $white;
        }
        p {
            font-weight: $weight-600;
            font-size: 16px;
            color: $white;
        }
    }
    .right-inner {
        width: 100%;
        height: 100%;
        padding: 40px;
        max-width: 580px;
        @media (max-width: 1024px) {
            height: auto;
        }
    }
}
.auth-policy {
    display: none !important;
    @include flexprop;
    @include flexjustify(flex-end);
    @include flexalign(center);
    padding-top: 50px;
    p {
        a {
            font-size: 14px;
            font-weight: $weight-500;
            line-height: 17px;
            color: $gray500;
            text-decoration: none;
        }
    }
    span {
        margin: 0 20px 9px;
    }
}
.remember-password {
    @include flexprop;
    @include flexalign(center);
    @include flexjustify(space-between);
    margin-top: 30px;
    .forgot-password-link {
        text-align: left;
        a {
            font-weight: $weight-600;
            font-size: 16px;
            line-height: 20px;
            color: $linkcolor;
            text-decoration: none;
        }
    }
}
.login-link {
    margin-top: 25px;
    @include flexprop;
    @include flexjustify(center);
    a {
        font-weight: $weight-600;
        font-size: 16px;
        line-height: 20px;
        color: $linkcolor;
        text-decoration: none;
    }
}
