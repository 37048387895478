.modal-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($black, 0.8);
    @include flexprop;
    @include flexalign(center);
    @include flexjustify(center);
    padding: 30px 16px;
    z-index: 999;
    &:not(.active) {
        transform: scale(0);
    }
    .modal-inner {
        background-color: $white;
        @include width;
        max-width: 500px;
        max-height: 100%;
        overflow: auto;
        @include radius(5px);
        &.width-850 {
            max-width: 850px;
        }
        &.width-600 {
            max-width: 600px;
        }
        .modal-header {
            padding: 20px 25px;
            background-color: $primary600;
            color: $white;
            h6 {
                font-size: 20px;
                font-weight: $weight-500;
            }
            img {
                filter: brightness(0) invert(1);
            }
        }
        .modal-body {
            padding: 25px;
            overflow: auto;
            &.height {
                overflow-y: auto;
                height: calc(100vh - 275px);
            }

            h5 {
                margin-bottom: 24px;
                font-size: 24px;
                text-align: center;
                color: $gray500;
                font-weight: $weight-700;
            }
            p {
                font-size: 18px;
                text-align: center;
                margin-top: 20px;
                margin-bottom: 24px;
                color: $secondary;
                line-height: 1.3;
                &.mt-15 {
                    margin-top: 15px;
                }
                &.mb-15 {
                    margin-bottom: 15px;
                }
            }
            .input-wrapper {
                margin-bottom: 18px;
                &.mb-0 {
                    margin-bottom: 0;
                }
                .input-icon {
                    &.small {
                        top: 33px;
                        right: 11px;
                    }
                }
            }
            .search-field-sec {
                margin-bottom: 20px;
                &.mb-0 {
                    margin-bottom: 0;
                }
                .search-wrapper {
                    max-width: 100%;
                    width: 100%;
                    input[type="search"] {
                        padding-left: 50px;
                        background-image: url("../images/search-icon.svg");
                        background-repeat: no-repeat;
                        background-position: 18px center !important;
                        &:focus {
                            background-color: #ffffff;
                        }
                    }
                }
                h3 {
                    font-weight: $weight-700;
                    color: $black;
                    font-size: 20px;
                }
            }
            .button-wrapper {
                margin-top: 35px;
                .btn {
                    min-width: 150px;
                }
                .btn + .btn {
                    margin: 0 0 0 40px;
                }
            }

            // modal-tab
            .modal-tabs {
                margin-bottom: 22px;
                border: 1px solid rgba(36, 131, 197, 0.309);
                .modal-tab-section {
                    @include flexprop;
                    ul {
                        @include flexprop;
                        overflow-x: auto;
                        max-width: 556px;
                        li {
                            background: $light-gray;
                            padding: 18px 10px;
                            text-align: center;
                            font-weight: $weight-400;
                            font-size: 16px;
                            color: $seconday-black;
                            white-space: nowrap;
                            position: relative;
                            &.active {
                                background: $primary600;
                                color: $white;
                                font-weight: $weight-700;
                                &:hover {
                                    .remove-icon {
                                        display: block;
                                        img {
                                            filter: brightness(0) invert(1);
                                        }
                                    }
                                }
                            }
                            &:not(:last-child) {
                                border-right: 1px solid rgba(36, 131, 197, 0.309);
                            }
                            .remove-icon {
                                position: absolute;
                                top: 3px;
                                right: 3px;
                                width: 14px;
                                height: 14px;
                                cursor: pointer;
                                display: none;
                            }
                            &:hover {
                                .remove-icon {
                                    display: block;
                                }
                            }
                        }
                    }
                    .add-button {
                        flex: 0 0 38px;
                        max-width: 38px;
                        padding-bottom: 0;
                        margin-top: 8px;
                        margin-left: 6px;
                        margin-right: 6px;
                        button {
                            width: 100%;
                            background: rgba(36, 131, 197, 0.1);
                            @include radius(8px);
                            padding: 10px 12px;
                        }
                    }
                    .subadmin-add-sec {
                        .input-wrapper {
                            margin-bottom: 0;
                            margin-top: 5px;
                            max-width: 185px;
                        }
                    }
                }
            }
            .checkbox-wrapper {
                padding: 15px 0;
                border-bottom: 1px solid $border400;
                &:last-child {
                    margin-bottom: 0;
                    border-bottom: 0;
                }
            }
        }
    }
    .large {
        max-width: 900px;
        min-width: 500px;
        width: auto;
        transition: none;
    }
    .with-action {
        h5 {
            margin-bottom: 20px;
            color: $black;
            font-weight: $weight-700;
        }
        p {
            font-size: 18px;
        }
    }
    .upload-sec {
        //   @include flexprop;
    }
    .upload-wrap {
        @include flexprop;
        margin-bottom: 18px;
        margin-right: 15px;
        .upload-file {
            width: 178px;
            height: 142px;
            overflow: hidden;
            position: relative;
            border: 1px dashed $black-dark;
            @include radius(20px);
            @include flexprop;
            @include flexalign(center);
            @include flexjustify(center);
            flex-direction: column;
            p {
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                color: $black-dark;
                margin: 15px 0;
            }
            .upload-btn {
                width: 95px;
                button {
                    height: 35px;
                    @include flexprop;
                    @include flexalign(center);
                    width: 100%;
                    input {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .upload-file-2 {
            position: relative;
            &.audio {
                width: 270px;
            }
            &.video {
                width: 178px;
            }
            audio {
                width: 100%;
            }
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            figure {
                width: 178px;
                height: 142px;
                @include radius(20px);
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    @include radius(20px);
                }
            }
            .close-icon {
                position: absolute;
                top: 8px;
                right: 8px;
                display: none;
                cursor: pointer;
                figure {
                    padding: 4px;
                    width: 18px;
                    height: 18px;
                    @include radius(50%);
                    @include flexprop;
                    @include flexalign(center);
                    @include flexjustify(center);
                    background: $white;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        @include radius(50%);
                        filter: invert(42%) sepia(64%) saturate(6261%) hue-rotate(327deg) brightness(97%) contrast(110%);
                    }
                }
            }
            &:hover {
                .close-icon {
                    display: block;
                }
            }
        }
    }
    .tags {
        ul {
            @include flexprop;
            flex-wrap: wrap;
            padding-bottom: 8px;
            li {
                max-width: 30%;
                flex: 0 0 30%;
                margin-right: 8px;
                background: $white200;
                @include radius(16px);
                font-weight: $weight-400;
                font-size: 14px;
                line-height: 22px;
                text-align: right;
                color: $seconday-black;
                margin-top: 8px;
                &:last-child {
                    margin-right: 0;
                }
                .text-wrapper {
                    @include flexprop;
                    @include flexalign(center);
                    @include flexjustify(center);
                    padding: 4px 16px;
                    span {
                        margin-left: 5px;
                        @include flexprop;
                        @include flexalign(center);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
