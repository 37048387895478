.tab-second {
    margin-top: 22px;
    ul {
        @include flexprop;
        @include flexjustify(flex-end);
        @include radius(24px);
        li {
            background: $light-gray;
            max-width: 325px;
            width: 100%;
            padding: 13px 25px;
            text-align: center;
            cursor: pointer;
            font-weight: $weight-400;
            font-size: 16px;
            color: $seconday-black;
            @include radius(5px);
            &.active {
                background: $primary600;
                color: $white;
                @include radius(5px);
                font-weight: $weight-700;
            }
            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
}
