*,
*:before,
*:after {
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
    display: block;
}

body {
    line-height: 1;
    font-family: $font-Montserrat;
    background-color: $gray800;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input {
    appearance: none;
    -webkit-appearance: none;
}
:focus {
    outline: 0;
}

// Scroll baar css
:root::-moz-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-moz-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: $gray100;
}
::-moz-scrollbar-track {
    background: #f2f4f7;
}

::-webkit-scrollbar-thumb {
    background: $primary600;
    @include radius(100px);
}
::-moz-scrollbar-thumb {
    border-radius: 100px;
    background: #2483c5;
}
::-webkit-scrollbar-thumb:hover {
    background: #2483c5;
}
::-moz-scrollbar-thumb:hover {
    background: #2483c5;
}

* {
    scrollbar-color: #2483c5 #f2f4f7;
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track-piece {
    background-color: #f2f4f7;
}

::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: #2483c5;
    border-radius: 100px;
}

input[type="search"]:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: url("../images/search-icon.svg") !important;
    background-color: #fff !important;
    color: fieldtext !important;
}


