/*---------- flex css -------------*/
@mixin flexprop {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
@mixin flexwrap {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}
@mixin flexalign($align) {
    align-items: $align;
    -webkit-align-items: $align;
}
@mixin flexjustify($just) {
    justify-content: $just;
    -webkit-justify-content: $just;
}
@mixin fwidth($width) {
    flex: 0 0 $width;
    width: $width;
}
@mixin margin-auto {
    margin: 0 auto;
}
/*---------- height and width css -------------*/
@mixin height($height: 100%) {
    height: $height;
}
@mixin width($width: 100%) {
    width: $width;
}
/*---------- border css -------------*/
@mixin radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
}
@mixin border($width, $color) {
    border: $width solid $color;
}
@mixin transition($trans: 0.5s) {
    -webkit-transition: $trans;
    -moz-transition: $trans;
    transition: $trans;
}
/*---------- after before css -------------*/
@mixin content($position: absolute, $left: 50%, $top: 50%, $transform: translate(-50%, -50%)) {
    content: "";
    position: $position;
    left: $left;
    top: $top;
    transform: $transform;
}
/*---------- background css -------------*/
@mixin bgImg($name, $size: cover, $position: center) {
    background-size: $size;
    background-repeat: no-repeat;
    background-position: $position;
}
/*---------- row css -------------*/
@mixin row($value: 12) {
    margin-right: -$value + px;
    margin-left: -$value + px;
    > div {
        padding-right: $value + px;
        padding-left: $value + px;
    }
}
/*---------- media query css -------------*/
$MediaQuery: true;
@mixin media($point) {
    @if ($MediaQuery) {
        $xl: "1199px";
        $lg: "991px";
        $md: "767px";
        $sm: "575px";
        @if $point==xl {
            @media screen and (max-width: $xl) {
                @content;
            }
        } @else if $point==lg {
            @media screen and (max-width: $lg) {
                @content;
            }
        } @else if $point==md {
            @media screen and (max-width: $md) {
                @content;
            }
        } @else if $point==sm {
            @media screen and (max-width: $sm) {
                @content;
            }
        }
    }
}

/*---------- box-shadow css -------------*/
@mixin box-shadow($params...) {
    -webkit-box-shadow: $params;
    -moz-box-shadow: $params;
    box-shadow: $params;
}
